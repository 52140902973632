<script setup lang="ts">
import { useMiniExplore } from "@/graphql/composables/mini-explore";
import useStationList from "@/graphql/composables/station";
import { EpgShow, type Player, Station, STATION_LOGOS } from "@/types/prime";
import { useEpgData } from "@/graphql/composables/getEpgData";
import { toFullYearFormatedDate } from "@/utilities/timeUtils";
import { mapStation } from "@/graphql/composables/mappers/station-mapper";
import { formatDateToYYYYMMDD } from "@/utilities/dateUtils";

definePage({ meta: { pageName: "station_selection" } });

const StationAds: Record<Station, string> = {
  "947": "/22659639123/947-Station-Page-take-over",
  capetalk: "/22659639123/CapeTalk_Station_Takeover",
  "702": "/22659639123/702_Station_Takeover",
  kfm: "/22659639123/KFM_Station_Takeover",
  none: "",
};

const route = useRoute<"/station/[id]">();
const station = route.params.id as Station;
const mappedStation = mapStation(station);
const isAutoPlay = route.query.autoPlay === "true";

const { stationDetail } = useStationList(station);

const { error, result: shows, load } = useEpgData(toFullYearFormatedDate());

mappedStation &&
  load(undefined, { station: mappedStation, date: formatDateToYYYYMMDD(new Date()) });

let autoUpdateTimeout: NodeJS.Timeout;

const currentShow = ref<EpgShow | undefined>();

watch([shows, stationDetail], () => {
  if (!shows.value?.length || !stationDetail.value) return;

  updateCurrentShow();
});

const updateCurrentShow = () => {
  const nowInMiliseconds = new Date().getTime();

  currentShow.value = shows.value?.find(
    (show) =>
      show.startTime <= nowInMiliseconds / 1000 &&
      show.duration + show.startTime >= nowInMiliseconds / 1000,
  );

  if (!currentShow.value) {
    currentShow.value = { title: "No shows", imageUrl: { lg: "", md: "", sm: "" } } as EpgShow;
    return;
  }

  autoUpdateTimeout = setTimeout(() => {
    updateCurrentShow();
  }, (currentShow.value.startTime + currentShow.value.duration - new Date().getTime() / 1000) * 1000);
};

const el = ref<HTMLElement | null>(null);

const { miniExploreLoading, miniExploreResult, loadMiniExplore } = useMiniExplore(el);

watch(
  stationDetail,
  () => {
    if (stationDetail.value) {
      loadMiniExplore(stationDetail.value.id);
    }
  },
  { immediate: true },
);

const playerData: Ref<Player> = computed(() => {
  if (!currentShow.value || !stationDetail.value)
    return {
      headline: "No shows",
      imageUrl: { lg: "", md: "", sm: "" },
      media: { duration: 0, startTime: 0 },
      mediaId: "",
      station: Station.None,
    };

  return {
    headline: currentShow.value.title,
    imageUrl: currentShow.value.imageUrl,
    media: {
      duration: currentShow.value.duration,
      startTime: currentShow.value.startTime,
    },
    mediaId: stationDetail.value.mediaId,
    station,
  };
});

onUnmounted(() => {
  clearTimeout(autoUpdateTimeout);
});
</script>

<template>
  <Error v-if="error" :message="error.message" />

  <div
    ref="el"
    class="-mx-4 h-full overflow-hidden overflow-y-auto px-4 pb-32 lg:m-0 lg:-mx-8"
    v-else
  >
    <div
      class="relative flex animate-pulse items-center justify-center lg:h-96"
      v-if="!currentShow || !stationDetail"
    >
      <div class="absolute inset-0 -mx-4 bg-loading opacity-80"></div>
      <div
        class="absolute z-10 h-[167px] w-[167px] rounded-full bg-loading lg:h-[264px] lg:w-[264px]"
      ></div>
    </div>

    <div v-else>
      <div class="grid justify-center">
        <GoogleAdManager :slot-id="StationAds[station]" />
      </div>
      <div class="relative">
        <header class="hidden lg:block">
          <div class="absolute inset-0 -inset-x-4 -z-10 h-96">
            <Image :src="currentShow.imageUrl" :alt="station" />
          </div>
          <div class="overlay absolute inset-0 -inset-x-4 -z-10 h-96"></div>
        </header>
        <header class="flex flex-row justify-between lg:hidden">
          <div class="w-16">
            <img
              :src="`/images/logos/${STATION_LOGOS[route.params.id as Station]}`"
              :alt="route.params.id"
            />
          </div>
          <Button class="w-max" theme="tertiary" @click="$router.push('/stations')">
            Change Station <IconChange class="w-5" />
          </Button>
        </header>
        <main>
          <Player :data="playerData" :auto-play="isAutoPlay" />
          <div class="flex justify-center pt-10">
            <router-link
              :to="`/station/${route.params.id}/guide`"
              class="rounded-lg bg-neutral-interaction px-10 py-2.5 text-center text-sm font-bold text-white transition-colors hover:bg-neutral-300"
            >
              Open Line-up
            </router-link>
          </div>
        </main>

        <MiniExplore
          v-if="miniExploreResult"
          :reels="miniExploreResult"
          :loading="miniExploreLoading"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlay {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(16, 16, 16, 0) 0.01%,
    #101010 100%
  );
  backdrop-filter: blur(15.458px);
}
</style>
